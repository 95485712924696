.edit-element-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #f6f6f6;
  }
  
  .edit-element-form {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .edit-element-form h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .edit-element-form label {
    display: block;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .edit-element-form input,
  .edit-element-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .edit-element-form button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
  }
  
  .edit-element-form button:hover {
    background-color: #2980b9;
  }

  .edit-element-form select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
  