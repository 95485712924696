/* Dashboard.css */

.dashboard-container {
    display: flex;
  }
  
  .dashboard {
    padding: 20px;
    flex-grow: 1;
  }
  
  .dashboard h1 {
    margin-bottom: 20px;
  }
  
  .dashboard-cards {
    display: flex;
    gap: 20px;
  }
  
  .dashboard-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
  }
  
  .dashboard-card h2 {
    margin-bottom: 10px;
  }
  
  .dashboard-card p {
    font-size: 2rem;
    font-weight: bold;
  }
  