.add-plan {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .add-plan h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .add-plan form {
    display: flex;
    flex-direction: column;
  }
  
  .add-plan label {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .add-plan input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .meals {
    margin-top: 1rem;
  }
  
  .meals h3 {
    margin-bottom: 1rem;
  }
  
  .add-plan button {
    padding: 1rem;
    background-color: #2ecc71;
    border: none;
    border-radius: 0.5rem;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
  }
  
  .add-plan button:hover {
    background-color: #27ae60;
  }
  