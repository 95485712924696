.create-element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f6f6f6;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.create-element-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-element-form input,
.create-element-form textarea {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.create-element-form textarea {
  min-height: 100px;
  resize: vertical;
}

.create-element-form input[type="file"] {
  border: none;
  padding: 0.5rem 0;
}

.create-element-form select {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #2ecc71;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.secondary-button:hover {
  background-color: #27ae60;
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  margin-bottom: 2rem;
}

.alert {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  text-align: center;
}

.alert-success {
  background-color: #dff0d8;
  color: #3c763d;
}

.alert-error {
  background-color: #f2dede;
  color: #a94442;
}
