.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f8f8f8;
}

.footer-section-one,
.footer-section-two {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-logo-container {
  max-width: 110px;
}

.footer-icons {
  display: flex;
  align-items: center; /* Align items in the center */
}

.footer-icons a {
  margin-right: 1.25rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  color: #585858;
}

.footer-section-two {
  margin-top: 2rem;
  justify-content: center;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 190px;
  margin: 0.5rem;
}

/* Link styles */
.footer-link {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.footer-link:hover {
  color: #2ecc71;
  border-bottom: 1px solid #2ecc71;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .footer-wrapper {
    flex-direction: row;
    align-items: flex-start;
    padding: 2rem 4rem;
  }

  .footer-section-one {
    width: auto;
    margin-right: auto;
  }

  .footer-section-two {
    flex: 1;
    justify-content: flex-end;
    margin-top: 0;
  }

  .footer-section-columns {
    align-items: flex-start;
  }
}
