.element-page {
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f6f6f6;
}

.element-content {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 1000px;
}

.element-image {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
}

.element-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 1rem;
}

.element-details {
  flex: 2;
  padding: 2rem;
}

.element-details h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.element-details p {
  margin-bottom: 1rem;
}

.diet-details p {
  margin-bottom: 0.5rem;
}

.price {
  font-size: 1.5rem;
  color: #27ae60;
  margin-bottom: 1rem;
}

.quantity {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.quantity label {
  margin-right: 1rem;
}

.quantity input {
  width: 60px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-button,
.whatsapp-button,
.edit-button,
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  flex: 1;
  margin: 0.5rem;
}

.order-button {
  background-color: #2ecc71;
}

.order-button:hover {
  background-color: #27ae60;
}

.whatsapp-button {
  background-color: #25d366;
}

.whatsapp-button:hover {
  background-color: #1ebd56;
}

.whatsapp-button svg {
  margin-right: 0.5rem;
}

.edit-button {
  background-color: #3498db;
}

.edit-button:hover {
  background-color: #2980b9;
}

.delete-button {
  background-color: #e74c3c;
}

.delete-button:hover {
  background-color: #c0392b;
}

.delete-button svg {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .element-content {
    flex-direction: column;
  }

  .element-image {
    max-width: 100%;
    padding: 0;
  }

  .buttons {
    flex-direction: column;
  }

  .order-button,
  .whatsapp-button,
  .edit-button,
  .delete-button {
    flex: none;
    width: 100%;
  }
}
