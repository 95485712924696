.list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.list-item {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.2s;
  width: 250px;
}

.list-item:hover {
  transform: scale(1.05);
}

.list-item-image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.list-item-details {
  padding: 1rem;
}

.list-item-title {
  color: #4c4c4c;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.list-item-price {
  color: #2ecc71;
  font-size: 1rem;
  font-weight: 600;
}

.add-to-cart-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #27ae60;
}

/* Search Bar */
.search-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.search-bar-container input {
  width: 100%;
  max-width: 400px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 2px solid #2ecc71;
  border-radius: 50px;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.search-icon {
  color: #2ecc71;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.search-bar {
  width: 100%;
  max-width: 400px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 2px solid #2ecc71;
  border-radius: 50px;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.search-bar:focus {
  border-color: #27ae60;
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination {
  display: flex;
  gap: 0.5rem;
}

.pagination button {
  background-color: #2ecc71;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
  background-color: #27ae60;
  transform: scale(1.1);
}

.pagination button.active {
  background-color: #1abc9c;
  transform: scale(1.15);
}
