/* Auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    max-width: 400px;
    margin: 2rem auto;
  }
  
  .auth-container h2 {
    margin-bottom: 2rem;
    color: #4c4c4c;
  }
  
  .auth-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .auth-container input {
    padding: 0.8rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
  }
  
  .auth-container .primary-button {
    padding: 0.9rem 1.75rem;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
  }
  
  .auth-container .primary-button:hover {
    background-color: #27ae60;
  }
  
  .auth-container .error {
    color: red;
    margin-bottom: 1rem;
  }
  