/* Contact.css */

.contact-wrapper {
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .accordion {
    margin-bottom: 1.5rem;
  }
  
  .accordion-item {
    border-bottom: 1px solid #ddd;
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    transition: background-color 0.3s;
  }
  
  .accordion-header:hover {
    background-color: #f1f1f1;
  }
  
  .accordion-body {
    padding: 1rem;
    background-color: #f9f9f9;
  }
  
  .accordion-body iframe {
    width: 100%;
    height: 300px;
    border: 0;
    margin-top: 1rem;
  }
  
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .whatsapp-button {
    display: flex;
    align-items: center;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .whatsapp-button svg {
    margin-right: 0.5rem;
  }
  
  .whatsapp-button:hover {
    background-color: #1ebc5c;
  }
  