/* Sidebar.css */
.sidebar {
  display: flex;
}

@media (max-width: 600px) {
  .sidebar {
    flex-direction: column;
  }
}

.MuiDrawer-paper {
  width: 250px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.MuiListItemButton-root {
  padding: 15px;
}

.MuiListItemIcon-root {
  min-width: 40px;
}

.MuiListItemText-root {
  font-size: 1.1rem;
  font-weight: 500;
}
