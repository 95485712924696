.checkout-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .checkout-section {
    margin-bottom: 20px;
  }
  
  h2, h3 {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .checkout-summary {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .checkout-summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .checkout-total {
    font-size: 18px;
    font-weight: bold;
  }
  
  .confirm-order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .confirm-order-btn:hover {
    background-color: #1ebd50;
  }
  
  .confirm-order-btn svg {
    margin-right: 10px;
  }
  