.cart-icon {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #27ae60;
  color: white;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000;
  font-size: 2rem;
  transition: all 0.3s ease;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.cart-icon .cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #e74c3c;
  color: white;
  padding: 6px 10px;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 25px;
  text-align: center;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.cart-icon.blink {
  animation: blink 0.5s linear 2;
}

  
  /* Cart Sidebar */
  .cart-sidebar {
    position: fixed;
    top: 0;
    right: -400px;
    width: 380px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    transition: right 0.3s ease-in-out;
    z-index: 1001;
    overflow-y: auto;
  }
  
  .cart-sidebar.open {
    right: 0;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: #e74c3c;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    border-bottom: 1px solid #eee;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .cart-item:hover {
    background-color: #f9f9f9;
  }
  
  .cart-item img {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cart-item .item-details {
    flex: 1;
  }
  
  .cart-item h4 {
    margin: 0 0 5px 0;
    font-size: 1.1rem;
    color: #333;
  }
  
  .quantity {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .quantity button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .quantity span {
    margin: 0 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .quantity button:hover {
    background-color: #e0e0e0;
  }
  
  .remove-btn {
    background: none;
    border: none;
    color: #e74c3c;
    font-size: 1.2rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .remove-btn:hover {
    color: #c0392b;
  }
  
  .total {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: right;
    margin-top: 20px;
  }
  
  .checkout {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .checkout button {
    padding: 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 10px;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .checkout button:first-child {
    background-color: #e74c3c; /* Clear Cart button color */
  }
  
  .checkout button:last-child {
    background-color: #27ae60; /* Proceed to Checkout button color */
  }
  
  .checkout button:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  