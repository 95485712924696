.filter-wrapper {
    margin: 1rem 0;
  }
  
  .toggle-filter-button {
    padding: 0.5rem 1rem;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .toggle-filter-button:hover {
    background-color: #27ae60;
  }
  
  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  .apply-filter-button {
    padding: 0.5rem 1rem;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 1rem;
  }
  
  .apply-filter-button:hover {
    background-color: #27ae60;
  }
  